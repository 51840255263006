<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <step-status :active="1"></step-status>
        <el-form
            :model="formData"
            ref="formData"
            label-position="top"
            v-loading="dataLoading"
        >
            <RasieBaseInfo :formData.sync="formData" :reFresh="reFresh"></RasieBaseInfo>
        </el-form>
        <div class="page-group-box MT10">
            <el-button @click="goBack">返回</el-button>
            <el-button
                :disabled="claimInfo && claimInfo.status == 2 ? false : true"
                type="primary"
                @click="submitForm"
                :loading="btnLoading"
                >下一步</el-button
            >
        </div>
    </div>
</template>

<script>
import StepStatus from "./components/step-status";
import RasieBaseInfo from './components/raise-baseInfo';
const defaultFormData = {
    title:'',
    startTime:'',
    endTime:'',
    datetimerange:[],
    raiseType:1,
    raiseMoney:undefined,
    raiseModel:2,
    poster:'',
    posterOriginal:'',
    musicIds:[],
    introduce:''
}
export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    components:{
        StepStatus,
        RasieBaseInfo
    },
    data(){
        return{
            redirect: this.$route.query.redirect || '/manage/raise/list',
            dataLoading: false,
            btnLoading:false,
            formData:{...defaultFormData},
            reFresh:false,
            claimInfo: null
        }
    },
    created() {
        this.getClaimInfo();
        if (this.id) {
            this.getDataDetail();
        }else{
            this.reFresh = true;
        }
    },
    methods:{
        //获取认证信息
        getClaimInfo(){
            this.$request({
                url: "/user/register/getClaimInfo",
                params: {userId:this.$store.state.userInfo.id},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.claimInfo = result;
                        const _msg = [
                            "您还未进行认证，无法使用众筹发布功能，建议您尽快认证",
                            "当前认证信息正在等待审核，请耐心等待审核结果",
                            "您的认证信息已通过审核",
                            `您的认证信息未通过审核（原因：${result.remarks}），请修改后重新提交审核`,
                        ]

                        if( result.status != 2 ){
                            this.$confirm(result.status ? _msg[result.status] : _msg[0], '认证信息', {
                                confirmButtonText: result.status == 3 ? '立即修改' : result.status == 1 ? '查看认证' : '立即认证',
                                showCancelButton:false,
                                showClose:false,
                                customClass:'mpop',
                                closeOnClickModal: false,
                                closeOnPressEscape: false,
                                type:"warning"
                            }).then( () => {
                                this.$router.push({
                                    path:'/manage/workbench/auth'
                                })
                            }).catch(() => {});
                        }
                    } else {
                        this.$message.error(msg || "获取认证信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取认证信息失败");
                })
                .finally(() => {
                    // this.dataLoading = false;
                });
        },
        //获取详情
        getDataDetail() {
            this.dataLoading = true;
            this.$request({
                url: "/raise/info",
                params: { id: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.formData = {
                            ...defaultFormData,
                            ...result,
                            datetimerange:result.startTime ? [result.startTime,result.endTime || result.startTime] : [],
                            musicIds: result.musics ? result.musics.map( v => v.musicId) : [],
                            raiseMoney: result.raiseMoneyFormat || result.raiseMoney || undefined
                        }; 
                        this.reFresh = true;
                    } else {
                        self.$message.error(msg || "获取信息失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取信息失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        //返回
        goBack() {
            if(this.redirect){
                this.$router.push({
                    path: this.redirect,
                });
            }else{
                this.$router.go(-1);
            }
        },
        //表单提交
        submitForm() {
            let self = this;
            
            self.$refs["formData"].validate((valid) => {
                if (valid) {

                    this.formData.startTime = this.formData.datetimerange[0];
                    this.formData.endTime = this.formData.datetimerange[1];
                    
                    const _url = "/raise/publish/base";
                    this.btnLoading = true;

                    this.$request({
                        url: _url,
                        data: {...this.formData},
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg, result } = res.data;
                            if (state == 1) {
                                //self.goBack()
                                this.$router.push({
                                    path: `/manage/raise/edit/step2/${this.id || result.id}`,
                                    query: {
                                        publishType: this.id ? 2 : 1,  //1、新建，2、编辑
                                        redirect: this.redirect//this.id ? '/manage/raise/daft' : '/manage/raise/list'//this.$router.currentRoute.fullPath,
                                    },
                                });
                            } else {
                                self.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            self.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                } else {
                    return false;
                }
            });
        },
    }
}
</script>